<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img class="dark align-center" :src="BackToSchoolTwo"></v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h1>What is it:</h1>
          <p>
            The goal of the "Back to School" Spikeball Tournmament is to collect
            backpacks and school supplies for those in need this school year!
          </p>
        </v-col>

        <v-col cols="12" sm="6">
          <h1>Admission:</h1>
          <p>
            To participate in the tournament each team is asked to donate
            <strong> $5 or one or more school supply item(s)</strong>.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <h1>Awards:</h1>
          <p>Open Champions (All Ages)</p>
          <p>Youth Champions (10-14yrs)</p>
          <p>Generous GIGI Award</p>
        </v-col>

        <v-col cols="12" sm="6">
          <h1>How To Play:</h1>
          <p>
            Send us an email with your team name, age group, and roster. (3
            players max)
          </p>
          <ContactButton title="Sign Up" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>General School Supplies</h2>
          <p>Backpack, Pencils, Notebook, Paper</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="ssbackpack"
            contain
            height="90"
          ></v-img>
        </v-col>

        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="sspencils"
            contain
            height="90"
          ></v-img>
        </v-col>

        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="ssnotebook2"
            contain
            height="90"
          ></v-img>
        </v-col>

        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="sspaper"
            contain
            height="90"
          ></v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <h2>Art Supplies</h2>
          <p>Glue, Crayons, Scissors, Erasers</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="ssglueTaco"
            contain
            height="90"
          ></v-img>
        </v-col>

        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="sscrayon"
            contain
            height="90"
          ></v-img>
        </v-col>

        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="ssscissors"
            contain
            height="90"
          ></v-img>
        </v-col>

        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="sserasers"
            contain
            height="90"
          ></v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Math & Science Supplies</h2>
          <p>Calculator, Protractor, Ruler, Lab Supplies</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="sscalculator"
            contain
            height="90"
          ></v-img>
        </v-col>

        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="ssprotractor"
            contain
            height="90"
          ></v-img>
        </v-col>

        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="ssruler"
            contain
            height="90"
          ></v-img>
        </v-col>

        <v-col cols="3">
          <v-img
            class="dark align-center"
            :src="sstube"
            contain
            height="90"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ContactButton from '@/components/ContactButton.vue'
import BackToSchoolTwo from '@/assets/images/BackToSchool2.svg'
import ssbackpack from '@/assets/images/ssbackpack.svg'
import sspencils from '@/assets/images/sspencils.svg'
import ssnotebook2 from '@/assets/images/ssnotebook2.svg'
import sspaper from '@/assets/images/sspaper.svg'
import ssglueTaco from '@/assets/images/ssglueTaco.svg'
import sscrayon from '@/assets/images/sscrayon.svg'
import ssscissors from '@/assets/images/ssscissors.svg'
import sserasers from '@/assets/images/sserasers.svg'
import sscalculator from '@/assets/images/sscalculator.svg'
import ssprotractor from '@/assets/images/ssprotractor.svg'
import ssruler from '@/assets/images/ssruler.svg'
import sstube from '@/assets/images/sstube.svg'

export default {
  components: { ContactButton },
  data() {
    return {
      BackToSchoolTwo: BackToSchoolTwo,
      ssbackpack: ssbackpack,
      sspencils: sspencils,
      ssnotebook2: ssnotebook2,
      sspaper: sspaper,
      ssglueTaco: ssglueTaco,
      sscrayon: sscrayon,
      ssscissors: ssscissors,
      sserasers: sserasers,
      sscalculator: sscalculator,
      ssprotractor: ssprotractor,
      ssruler: ssruler,
      sstube: sstube,
    }
  },
}
</script>

<style></style>
