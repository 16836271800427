<template>
  <div>
    <v-btn class="primary" :href="mailto">
      {{ title }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["title"],

  data() {
    return {
      mailto:
        "mailto:sydney@bornagainstrength.com?" +
        encodeURI("subject=Send me more Info"),
    };
  },
};
</script>

<style>
</style>